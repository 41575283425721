import React, { useState, useEffect } from 'react';
import { CartIconOOI } from '@wix/pro-gallery-artstore-statics';
import './styles/dynamic/artstoreCartIconWixStyles.scss';
import '../../Gallery/Widget/GalleryWrapper/styles/commonStoreStaticsCss.global.scss';
import '../../../styles/artstorePagesWixStyles.global.scss';
import CartIcon from './cartSVGs.svg';
import { st, classes } from './styles/cartIcon.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  isAlbumTemplateV8,
  shouldAllowSelections,
} from '../../../common/utils';

const DEFUALT_CART_SIZE = 35;

export default (props: any) => {
  const {
    isPreview,
    isEditor,
    host,
    loggedIn,
    currentPageName,
    isAlbumV8,
  } = props;
  const [pushedToRight, setPushedToRight] = useState(props.pushToRight);
  const [hidden, setHidden] = useState(props.hidden);
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const { dimensions } = host;
  const { width, height } = dimensions;

  const onRefresh = ({ data }: { data: any }) => {
    if (data.type === 'refreshData') {
      const albumData = data?.data?.albumData;
      const isStoreAlbum = albumData?.isStoreAlbum;
      setHidden(!isStoreAlbum);
      setPushedToRight(
        !isAlbumTemplateV8(albumData) &&
          !shouldAllowSelections(albumData, currentPageName),
      );
    }
  };

  useEffect(() => {
    props.pushToRight !== pushedToRight && setPushedToRight(props.pushToRight);
  }, [props.pushToRight]);

  useEffect(() => {
    props.hidden !== hidden && setHidden(props.hidden);
  }, [props.hidden]);

  useEffect(() => {
    if (isPreview) {
      window.addEventListener('message', onRefresh);
      return () => {
        window.removeEventListener('message', onRefresh);
      };
    }
  }, []);

  const demoCartSize = width || DEFUALT_CART_SIZE;
  return isEditor && !isPreview ? (
    <div
      className={classes.demoCart}
      style={{
        width: demoCartSize,
        height: demoCartSize,
      }}
    >
      <CartIcon width={demoCartSize} />
      <div className={classes.demoCartCounter}>0</div>
    </div>
  ) : (
    <div
      className={st(classes.container, {
        pushToRight: pushedToRight && !isEditor,
        notLoggedIn: !isAlbumV8 && !loggedIn && !pushedToRight && !isEditor,
        isMobile,
      })}
      data-hook="cart-icon"
    >
      {!hidden && <CartIconOOI {...props} />}
    </div>
  );
};
